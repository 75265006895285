import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { env } from "../env";
import { getLocalStorage } from "../utils/function/localStorage";

export const buySellApi = createApi({
  reducerPath: "buySellApi",
  baseQuery: fetchBaseQuery({
    baseUrl: env.BASE_URL,
    // mode: 'cors',
    prepareHeaders: (headers) => {
      headers.set("x-auth-token", getLocalStorage("tp_Aa_uth_access"));
      return headers;
    },
  }),
  tagTypes: ["systemWallet"], // automatic-data fetching
  endpoints: (builder) => ({
    // get system wallet
    getSystemWallet: builder.query({
      query: () => "/common/get_system_wallet",
      method: "GET",
      providesTags: ["systemWallet"],
    }),
    // get system wallet
    getSystemWalletHistory: builder.query({
      query: () => "/common/get_swap_history",
      method: "GET",
      providesTags: ["systemWallet"],
    }),
    // post swap data
    sendSwapData: builder.mutation({
      query: (body) => ({
        url: "/secure/swap_token",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["systemWallet"],
    }),
    getExampleMessage: builder.query({
      query: () => "/example_message",
      method: "GET",
    }),
  }),
});

export const {
  useGetSystemWalletQuery,
  useSendSwapDataMutation,
  useGetSystemWalletHistoryQuery,
  useGetExampleMessageQuery,
} = buySellApi;
