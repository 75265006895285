import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { env } from "../env";
import { getLocalStorage } from "../utils/function/localStorage";

export const AddMoneyApi = createApi({
  reducerPath: "addMoneyApi",
  baseQuery: fetchBaseQuery({
    baseUrl: env.BASE_URL,
    // mode: 'cors',
    prepareHeaders: (headers) => {
      headers.set("X-Auth-Token", getLocalStorage("tp_Aa_uth_access"));
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getPaymentInfo: builder.query({
      query: () => `/common/get_payment_info`,
    }),
    addDeposit: builder.mutation({
      query: (body) => ({
        url: "/secure/deposit_amount",
        method: "POST",
        body,
      }),
      invalidatesTags: ["tpWallet"],
    }),
    submitPremiumPaymentProof: builder.mutation({
      query: (data) => ({
        url: "/secure/submit-premium-payment-proof",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["premiumPurchase"],
    }),
    submitPremiumPlusPaymentProof: builder.mutation({
      query: (data) => ({
        url: "/secure/submit-premium-plus-payment-proof",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["premiumplusPurchase"],
    }),
    getDepositHistory: builder.query({
      query: ({ page, limit }) => `/secure/get_deposit_history?page=${page}&limit=${limit}`,
    }),
  }),  
});

export const {
  useGetPaymentInfoQuery,
  useAddDepositMutation,
  useSubmitPremiumPaymentProofMutation,
  useSubmitPremiumPlusPaymentProofMutation,
  useGetDepositHistoryQuery
} = AddMoneyApi;
