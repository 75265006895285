import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { userApi } from "../services/userApi";
import { SocialMediaTask } from "../services/CreateSocialMediaTaskApi";
import { AddMoneyApi } from "../services/addMoneyApi";
import { buySellApi } from "../services/buySell";
import { withdrawApi } from "../services/withdrawApi";
import { redeemApi } from "../services/redeemApi";
import { leaderBoardApi } from "../services/leaderBoardApi";
import loginPopupDisplayReducer from "./Slices/loginPopupDisplaySlice";
import smTaskSubTaskReducer from "./Slices/smTaskSubTaskSlice";
import userProfileReducer from "./Slices/userProfileSlice";
import { SocialMediaHandle } from "../services/socialMediaHandle";
import { stakeApi } from "../services/stakeApi";
import { tradeApi } from "../services/tradeApi";
import whatsAppModalPopupDisplayReducer from "./Slices/whatsAppModalPopupDisplay";
export const store = configureStore({
  reducer: {
    [userApi.reducerPath]: userApi.reducer,
    [SocialMediaTask.reducerPath]: SocialMediaTask.reducer,
    [AddMoneyApi.reducerPath]: AddMoneyApi.reducer,
    [buySellApi.reducerPath]: buySellApi.reducer,
    [withdrawApi.reducerPath]: withdrawApi.reducer,
    [redeemApi.reducerPath]: redeemApi.reducer,
    [leaderBoardApi.reducerPath]: leaderBoardApi.reducer,
    [SocialMediaHandle.reducerPath]: SocialMediaHandle.reducer,
    [stakeApi.reducerPath]: stakeApi.reducer,
    [tradeApi.reducerPath]: tradeApi.reducer,
    loginPopupDisplay: loginPopupDisplayReducer,
    smTaskSubTask: smTaskSubTaskReducer,
    userProfile: userProfileReducer,
    whatsAppModalPopupDisplay: whatsAppModalPopupDisplayReducer,
  },
  devTools: false,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      userApi.middleware,
      SocialMediaTask.middleware,
      AddMoneyApi.middleware,
      buySellApi.middleware,
      withdrawApi.middleware,
      redeemApi.middleware,
      leaderBoardApi.middleware,
      SocialMediaHandle.middleware,
      stakeApi.middleware,
      tradeApi.middleware,
    ]),
  /** here will be more reducer */
});

setupListeners(store.dispatch);
