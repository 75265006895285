import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoginPopupDisplay: false,
};

export const loginPopupDisplaySlice = createSlice({
  name: "isLoginPopupDisplay",
  initialState,
  reducers: {
    loginPopupDisplayHide: (state) => {
      state.isLoginPopupDisplay = true;
    },
  },
});

export const { loginPopupDisplayHide } = loginPopupDisplaySlice.actions;

export default loginPopupDisplaySlice.reducer;
