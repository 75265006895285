import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { env } from "../env";
import { getLocalStorage } from "../utils/function/localStorage";

export const redeemApi = createApi({
  reducerPath: "redeemApi",
  baseQuery: fetchBaseQuery({
    baseUrl: env.BASE_URL,
    prepareHeaders: (headers) => {
      headers.set("X-Auth-Token", getLocalStorage("tp_Aa_uth_access"));
      return headers;
    },
  }),
  tagTypes: ["redeem"],
  endpoints: (builder) => ({
    submitRedeem: builder.mutation({
      query: (data) => ({
        url: "/secure/submit_redeem",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["redeem"],
    }),
    getRedeemConversionRatio: builder.query({
      query: () => "/secure/get_redeem_conversion_ratio",
      providesTags: ["redeem"],
    }),
    submitCoupon: builder.mutation({
      query: (body) => ({
        url: "/secure/submitCoupon",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Point"],
    }),
  }),
});

export const {
  useSubmitRedeemMutation,
  useGetRedeemConversionRatioQuery,
  useSubmitCouponMutation,
} = redeemApi;
