import React, { useEffect, useState } from "react";
import BottomSheet from "../../components/BottomSheet";
import { useNavigate } from "react-router-dom";
import TopHeader from "../../components/TopHeader";
import GoogleLogin from "react-google-login";
import { env } from "../../env";
import useGoogleLogin from "../../hooks/useGoogleLogin";

const AuthSplashScreen = () => {
  const navigate = useNavigate();
  const { responseGoogle, resFailed } = useGoogleLogin();
  const [showMobileLogin, setShowMobileLogin] = useState(false);

  useEffect(() => {
    const detectCountry = async () => {
      try {
        const response = await fetch(
          `https://ipgeolocation.abstractapi.com/v1/?api_key=cceebbeb66fe43368c307932eaa93b96`
        );
        const data = await response.json();
        console.log(data);
        setShowMobileLogin(data.country_code === "IN");
      } catch (error) {
        console.error("Error detecting country:", error);
        setShowMobileLogin(false);
      }
    };

    // Check if we have a cached result
    const cachedCountry = localStorage.getItem("userCountry");
    if (cachedCountry) {
      setShowMobileLogin(cachedCountry === "IN");
    } else {
      detectCountry();
    }
  }, []);

  return (
    <div className="tpu__authSplashScreen__wrapper layoutContainer">
      {/* Header */}
      <TopHeader title="Login/Register" para="Ready to play and earn?" />
      {/* Bottom Sheet */}
      <BottomSheet height="85%">
        <div className="tpu__splashScreen__bottomSheet__content">
          <div className="google__login__btn">
            {/* <button type="button" className="google__btn">
              <img
                src="https://res.cloudinary.com/green-valley-grocery/image/upload/v1667014540/tp-app-assests/icons/google_pnf6be.png"
                alt="google icon"
              />
              <span>sign in with google</span>
            </button> */}
            <GoogleLogin
              clientId={env.google_client_id}
              buttonText="Sign in with google"
              onSuccess={responseGoogle}
              onFailure={resFailed}
              cookiePolicy={"single_host_origin"}
              className="google__btn"
            />
          </div>

          <div className="tpu__separator">
            <p className="dashed__separator">Or</p>
            <p className="separator__text">Or</p>
          </div>

          <div className="google__login__btn otherLoginButtons">
            {/* <button
              type="button"
              className="google__btn"
              onClick={() => navigate("/loginWithMobile")}
            >
              <img
                src="https://res.cloudinary.com/djfzcmvmr/image/upload/v1714559065/TaskPlanet/computer-icons-mobile-phones-clip-art-telephone-call-phone-number-icon-removebg-preview_gaolbp.png"
                alt="google icon"
              />
              <span>mobile</span>
            </button> */}
            <button
              type="button"
              className="google__btn"
              onClick={() => navigate("/loginWithEmail")}
            >
              <img
                src="https://res.cloudinary.com/djfzcmvmr/image/upload/v1714558787/TaskPlanet/2250206_se0etj.png"
                alt="google icon"
              />
              <span>email</span>
            </button>
          </div>
        </div>
      </BottomSheet>
    </div>
  );
};

export default AuthSplashScreen;
