import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isWhatsAppModalPopupDisplay: false,
};

export const whatsAppModalPopupDisplaySlice = createSlice({
  name: "isWhatsAppModalPopupDisplay",
  initialState,
  reducers: {
    whatsAppModalPopupDisplayHide: (state) => {
      state.isWhatsAppModalPopupDisplay = true;
    },
  },
});

export const { whatsAppModalPopupDisplayHide } =
  whatsAppModalPopupDisplaySlice.actions;

export default whatsAppModalPopupDisplaySlice.reducer;
