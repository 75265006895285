import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { env } from "../env";
import { getLocalStorage } from "../utils/function/localStorage";

export const tradeApi = createApi({
    reducerPath: "tradeApi",
    baseQuery: fetchBaseQuery({
        baseUrl: env.BASE_URL,
        prepareHeaders: (headers) => {
            headers.set("X-Auth-Token", getLocalStorage("tp_Aa_uth_access"));
            return headers;
        },
    }),
    tagTypes: ["Trade"],

    endpoints: (builder) => ({
        getAllPolls: builder.query({
            query: () =>
                `/common/get-all-polls`,
            providesTags: ["Trade"]

        }),
        getAllEvents: builder.query({
            query: () =>
                `/common/get-all-events`,
            providesTags: ["Trade"]
        }),
        getAllLeagues: builder.query({
            query: () =>
                `/common/get-all-leagues`,
            providesTags: ["Trade"]
        }),

        placeBet: builder.mutation({
            query: (betData) => {
                return {
                    url: "/common/place-bet",
                    method: "POST",
                    body: betData,
                    headers: {
                        "Content-Type": "application/json",
                    },
                };
            }
        }),
        pollParticipateHistory: builder.query({
            query: () =>
                `common/participation-history`,
            providesTags: ["Trade"]
        }),

        updatePollEntryCount: builder.mutation({
            query: ({ pollId, optionId }) => ({
                url: `/common/update-poll-entry-count/${pollId}/${optionId}`, 
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
            }),
            invalidatesTags: ["Trade"], 
        }),
    }),
});


export const {
    useGetAllPollsQuery,
    useGetAllEventsQuery,
    useGetAllLeaguesQuery,
    usePlaceBetMutation,
    usePollParticipateHistoryQuery,
    useUpdatePollEntryCountMutation,
} = tradeApi;
