import { useEffect, useState } from "react";
import { env } from "../env";
import { useLocation, useNavigate } from "react-router-dom";
import { Notification } from "../components/ToastNotification";
import { gapi } from "gapi-script";
import { useAddGoogleLoginMutation } from "../services/userApi";
import { savedLocalStorage } from "../utils/function/localStorage";

const useGoogleLogin = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sponsorID = searchParams.get("sponsorId") || searchParams.get("sponsorid");


  // Mutation hook to handle Google login API call
  const [addGoogleLogin, { data: eData, error: gError, isLoading: loading }] =
    useAddGoogleLoginMutation();

  // State to hold user information from Google
  const [value, setValue] = useState({});

  // Function to handle Google login response
  const responseGoogle = async (response) => {
    const profile = response?.profileObj;
    const tokenId = response?.tokenId;

    if (profile && tokenId) {
      const googleUserData = {
        tokenId,
        first_name: profile.givenName,
        last_name: profile.familyName,
        email: profile.email,
        androidApp: true,
        sponsorid:sponsorID 
      };

      savedLocalStorage("loginUserInfo", JSON.stringify(googleUserData));
      // Set the user data and call the API
      setValue(googleUserData);
      await addGoogleLogin(googleUserData);
    } else {
      Notification("Google login failed. Please try again.", "error");
    }
  };

  const resFailed = () => {
    Notification("All fields are required", "error");
  };

  // Effect to initialize Google API
  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: env.google_client_id,
        scope: "email",
        plugin_name: "faucets-app",
      });
    };

    gapi.load("client:auth2", initClient);
  }, []);

  // Effect to handle success or error after the login mutation
  useEffect(() => {
    if (eData?.message) {
      Notification(eData.message, "success");
      localStorage.setItem("tp_Aa_uth_access", eData.token);
      navigate("/home");
    } else if (gError) {
      Notification(
        gError?.data?.message || "Login failed. Please try again.",
        "error"
      );
      navigate("/googleAdditionalInfo");
    }
  }, [eData, gError, navigate]);

  return {
    responseGoogle,
    resFailed,
    loading,
  };
};

export default useGoogleLogin;
