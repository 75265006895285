import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { env } from "../env";
import { getLocalStorage } from "../utils/function/localStorage";


export const stakeApi=createApi({
    reducerPath:"stakeApi",

    baseQuery:fetchBaseQuery({
        baseUrl:env.BASE_URL,
        prepareHeaders: (headers) => {
            headers.set("X-Auth-Token", getLocalStorage("tp_Aa_uth_access"));
            return headers;
          },

    }),
      tagTypes:["Stake"],

      endpoints:(builder)=>({
        takeUserStake:builder.mutation({
            query:(body)=>({
                url:"/secure/takeUserStake",
                method:"POST",
                body,
            }),
            invalidatesTags: ["Stake"], 
        }),

        getAllStake: builder.query({
          query: () =>
            `/secure/getAllStakes`,
          providesTags: ["Stake"], 
        }),
        getAllStakeProfile: builder.query({
          query: ({ username, pageno }) => {
            console.log("User:", username, "Page:", pageno);
            return {
              url: `/secure/getAllStakeProfile/${username}?pageno=${pageno}`,
              method: "GET",
            };
          },
          providesTags: ["Stake"],
        }),


        unStakeUserStake: builder.mutation({
          query: (id) => {
            console.log(id); 
            return {
              url: "/secure/unStakeUserStake",
              method: "POST",
              body:{id},
            };
          },
          invalidatesTags: ["Stake"],
        })
        
              



      
      }),



});


export const {
   

   useGetAllStakeQuery,
   useTakeUserStakeMutation,
   useGetAllStakeProfileQuery,
   useUnStakeUserStakeMutation
}=stakeApi;