import React, { useEffect, useRef, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { GoQuestion } from "react-icons/go";
import { MdEmail } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
  useCheckEmailOtpMatchMutation,
  useGetValidateEmailQuery,
  useSendEmailOtpMutation,
  useSendEmailForgotOtpMutation,
} from "../../services/userApi";
import { Notification } from "../../components/ToastNotification";
import { Validate } from "../../components/validation";
import forgetPassword from "../../assets/images/forgetPassword.png";

const EmailForgotPasswordScreen = () => {
  const [isFocus, setFocus] = useState(false);
  const navigate = useNavigate();
  const [otpSent, setOtpSent] = useState(false);
  const [countdown, setCountdown] = useState(40);
  const [otpCode, setOtpCode] = useState("");
  const [error, setError] = useState("");

  // OTP Field ***********************************
  const inputRefs = useRef([]);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const handleChangeOtp = (value, index) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    if (value.length === 1 && index < 3) {
      inputRefs.current[index + 1].focus();
    }
    if (newOtp.every((item) => item !== "")) {
      setOtpCode(newOtp.join(""));
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (otp[index] === "" && index > 0) {
        inputRefs.current[index - 1].focus();
      } else {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
      }
    }
  };
  // OTP Field End *****************************
  const [user, setUser] = useState({
    email: "",
  });

  const { data: getValidateEmail, refetch } = useGetValidateEmailQuery(
    `${user?.email}`
  );

  const handleChange = (e) => [
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    }),
  ];

  // Send Email Otp
  // const [
  //   sendEmailOtp,
  //   { data: sendOtpData, error: sendOtpError, isLoading: sendOtpIsLoading },
  // ] = useSendEmailOtpMutation();

  const [
    sendEmailForgotOtp,
    {
      data: sendEmailForgotOtpData,
      error: sendEmailForgotOtpError,
      isLoading: sendEmailForgotOtpIsLoading,
      isSuccess: sendEmailForgotOtpIsSuccess,
      isError: sendEmailForgotOtpIsError,
    },
  ] = useSendEmailForgotOtpMutation();

  // handle form error
  useEffect(() => {
    setError("");
    setError(Validate(user));
  }, [user]);

  useEffect(() => {
    if (sendEmailForgotOtpData) {
      setOtpSent(true);
      setCountdown(40);
      Notification(
        sendEmailForgotOtpData?.message || "OTP Sent Successfully",
        "success"
      );
    }
    if (sendEmailForgotOtpError) {
      Notification(
        sendEmailForgotOtpError?.data?.data?.message || "Something went wrong",
        "error"
      );
    }
  }, [sendEmailForgotOtpData, sendEmailForgotOtpError]);

  const handleSendEmailOtp = async () => {
    const obj = {
      email: user?.email,
    };
    await sendEmailForgotOtp(obj);
  };

  // Check Match Email top
  const [
    checkEmailOtpMatch,
    {
      data: checkOtpMatchData,
      error: checkOtpMatchError,
      isLoading: checkOtpMatchIsLoading,
    },
  ] = useCheckEmailOtpMatchMutation();

  useEffect(() => {
    if (checkOtpMatchData) {
      Notification(checkOtpMatchData?.message, "success");
      navigate("/emailResetPassword", {
        state: {
          email: user?.email,
          otp: otpCode,
        },
      });
    }
    if (checkOtpMatchError) {
      Notification(checkOtpMatchError?.data?.message, "error");
    }
  }, [checkOtpMatchData, checkOtpMatchError, navigate]);

  const handleOtpMatch = async () => {
    await checkEmailOtpMatch({
      email: user?.email,
      otp: otpCode,
    });
  };

  // OTP timer
  useEffect(() => {
    if (otpSent) {
      const timer = setInterval(() => {
        setCountdown((prevCount) => countdown > 0 && prevCount - 1);
      }, 1000);

      if (countdown === 0) {
        setOtp(["", "", "", ""]);
      }

      return () => clearInterval(timer);
    }
  }, [otpSent, countdown]);

  return (
    <div className="emailForgotPassword__wrapper layoutContainer">
      <div className="emailForgotPassword__header">
        <div className="tpu__header__widgets">
          <span className="leftArrowIcon">
            <FaArrowLeftLong onClick={() => navigate(-1)} />
          </span>
          <div className="tpu__header__name">
            <h2>Forgot Password</h2>
          </div>
          <span className="questionIcon">
            <GoQuestion />
          </span>
        </div>
      </div>
      <div className="emailForgotPassword__content__area">
        <div className="emailForgotPassword__heading">
          <h2>Forgot Password</h2>
          <p>
            Enter the email associated with your account and we'll send an email
            with instructions to reset your password.
          </p>
        </div>
        <div className="emailForgotPassword__image">
          <img src={forgetPassword} alt="img" />
        </div>
        {!otpSent ? (
          <div className="emailForgotPassword__inputField">
            <label htmlFor="email">Email Address</label>
            <div className="emailField">
              <span>
                <MdEmail style={{ color: isFocus ? "#0b59f9" : "#000" }} />
              </span>
              <input
                type="text"
                name="email"
                value={user.email}
                className="emailInput"
                onChange={handleChange}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                placeholder="Enter your registered email"
                id="email"
              />
            </div>
            {error.email && <p style={{ color: "red" }}>{error?.email}</p>}
            {!error?.email && getValidateEmail && (
              <p
                style={{
                  color: !getValidateEmail?.data ? "red" : "green",
                  fontSize: "12px",
                }}
              >
                {getValidateEmail?.data
                  ? "Email exist in database"
                  : user?.email?.includes("@") && "Email not exist in database"}
              </p>
            )}

            <div className="submitButton">
              <button
                type="submit"
                className="loginButton"
                // disabled={!data.email || sendOtpIsLoading}
                disabled={!(!error?.email && getValidateEmail?.data)}
                onClick={handleSendEmailOtp}
              >
                <span>
                  {sendEmailForgotOtpIsLoading ? "Loading..." : "Send OTP"}
                </span>
              </button>
            </div>
          </div>
        ) : (
          <div className="emailForgotPassword__inputField">
            <div className="mobileLoginForm">
              <div
                className="mobileNumberField"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  background: "transparent",
                  border: "none",
                }}
              >
                {otp.map((data, index) => (
                  <input
                    key={index}
                    ref={(el) => (inputRefs.current[index] = el)}
                    type="text"
                    maxLength="1"
                    value={data}
                    onChange={(e) => handleChangeOtp(e.target.value, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    style={{
                      width: "40px",
                      height: "40px",
                      textAlign: "center",
                      margin: "5px",
                      fontSize: "22px",
                      outline: "none",
                      background: "#f4f7ff",
                      border: "1px solid lightgray",
                      borderRadius: "5px",
                    }}
                  />
                ))}
              </div>
              <div
                style={{
                  color: "gray",
                  margin: "10px 0px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "4px",
                  fontFamily: "var(--font-family)",
                  fontSize: "14px",
                }}
              >
                Didn't receive OTP?
                {countdown > 0 && (
                  <p>
                    {" "}
                    Resend in{" "}
                    <strong style={{ fontWeight: "700" }}>
                      {countdown} Seconds
                    </strong>
                  </p>
                )}
              </div>
            </div>

            {countdown > 0 ? (
              <div className="submitButton">
                <button
                  type="button"
                  className="loginButton"
                  disabled={checkOtpMatchIsLoading}
                  onClick={handleOtpMatch}
                >
                  <span>
                    {checkOtpMatchIsLoading ? "Loading..." : "Send Otp"}
                  </span>
                </button>
              </div>
            ) : (
              <div className="submitButton">
                <button
                  type="button"
                  className="loginButton"
                  disabled={sendEmailForgotOtpIsLoading}
                  onClick={handleSendEmailOtp}
                >
                  <span>
                    {sendEmailForgotOtpIsLoading ? "Loading..." : "Resend OTP"}
                  </span>
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default EmailForgotPasswordScreen;
